@import "src/client_customizations/scss/client_master.scss";

#MetroBikeAccess {
    background-image: url(../../assets/images/MetroBike.svg);
    background-repeat: no-repeat;
    background-position: "left center";
    background-size: "cover";
    display: flex;
    flex-direction: row;
    height: 400px;
    
    .left-box {
        width: 35%;
    }
    .right-box {
        margin: 1rem;
        .title {
                display: flex;
                margin: 2rem 0rem 0rem 0rem;
                font-size: 40px;
                font-weight: 600;
            }
        
            .subtitle {

                text-align: left;
                font-size: 16px;
                font-weight: 600;
                margin: 1rem 0rem 1rem 0rem;
                .metroBike-link {
                    color: black;
                    text-decoration: underline;
                    
                }
            }
        
            .access-button {
                display: flex;
                
                margin: 1rem 0rem 1rem 0rem;
            }
        
            .help-buttons {
                display: flex;
                
                margin: 2rem 0rem 1rem 0rem;
            }
            .access-help {
                margin-left: 2rem;
            }
    }
    
    
}