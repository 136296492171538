@import "src/client_customizations/scss/client_master.scss";

.ChargingMap {
	iframe {
		max-width: 100%;
		width: 100%;
		height: 748px;
		border:none;
	}
}
