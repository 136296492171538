@import "src/client_customizations/scss/client_master.scss";
.Footer {
	background-color: $GenericFooter-background-color;
	padding-top: 20px;
	color: $GenericFooter-color;
	.h3 {
		margin-bottom: 5px;
		font-size: $GenericFooter-heading-font-size;
		color: $GenericFooter-heading-color;
	}
	a {
		transition: 0.3s all ease;
		-webkit-transition: 0.3s all ease;
		font-size: $GenericFooter-links-font-size;
		line-height: $GenericFooter-links-line-height;
		color: $GenericFooter-links-color;
		&:hover {
			color: $GenericFooter-links-hover-color;
			padding-left: 5px;
			text-decoration: underline;
		}
	}
	.small {
		color: $GenericFooter-links-color;
	}
	.p {
		color: $GenericFooter-links-color;
	}
}

.disclaimer-container {
       div {
              padding:0 !important;
              background-color:transparent !important;

              img {
                     max-width: 100%;
                     margin:0 0 20px;
              }

              p {
                     color:#a9a9a9;
                     font-size:11px;
                     line-height: 2em;
              }
       }
}