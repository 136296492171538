@import "src/client_customizations/scss/client_master.scss";

.inventory-filter-controls {
    .ev-condition-button {
		background-color: $Inventory-condition-background;
		border: $Inventory-condition-border;
		border-radius: $Inventory-condition-border-radius;
        padding: $Inventory-condition-padding;
    }
    
	.ev-condition-button.active {
        background-color: $Inventory-condition-background-active;
        color: $Inventory-filter-active-font-color;
	}

    .dealer-card {
        background-color: $Inventory-dealer-card-background;
        border: $Inventory-dealer-card-border;
        border-radius: $Inventory-dealer-card-border-radius;
    }
    .dealer-card.active {
        background-color: $Inventory-dealer-card-background-active;
        color: $Inventory-filter-active-font-color;
    }
    .dealer-card:not(:last-child) {
        margin-bottom: 1rem;
    }
    .dealer-grid-container {
		display: flex;
		flex-direction: column;
		button {
			border: 1px solid #DFDFDF ;
			margin-bottom: 8px;
			background-color: $Inventory-condition-background;
			&:hover {
                background-color: $Inventory-dealer-card-background-active;
                color: $Inventory-filter-active-font-color;
			}
        }
        img {
            background-color: $Inventory-dealer-image-background;
            border-radius: $Inventory-dealer-card-border-radius;
        }
	}
}