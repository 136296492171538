@import "src/client_customizations/scss/client_master.scss";

section.container.homepage-jumbotron {
	display: flex;
	align-items: center;
}
.homepage-jumbotron {
	.big-promise {
		padding: $HomePage-big-promise-padding;
		background-color: $HomePage-big-promise-background-color;
		color: $HomePage-big-promise-color;
		text-shadow: $HomePage-big-promise-text-shadow;
		min-height: $HomePage-big-promise-min-height;
		width: 100%;
	}
}
#link-card-buttons {
	margin-top: -7rem;
	padding: 3rem 1rem 1rem 1rem;
	background: transparent;
}
#why-choose-evs {
	padding: 0rem 1rem;
}
.big-promise-caption {
	top: 0;
	left: 0;
	position: absolute;
	padding: 1.5rem 1rem;
}
.eRides-link {
	display: flex;
	justify-content: center;
	font-size: 18px;
	text-align: left;

	.eRides-button {
		display: flex !important;
		justify-content: center !important;
		color: #FFF;
		background-color: #D8400E;
		width: 868px;
		padding: 16px 24px 16px 24px;
		border-radius: 5px;
		border: 1px;
		.eRides-bike {
				margin-right: 30px;
			}
		.eRides-text {
			margin-top: 10px;
		}
	}

	

	.underline {
		text-decoration: underline;
	}
}
.learn-more-link {
	display: flex;
	justify-content: center;

	
	.learn-more-button {
		margin-top: 20px;
		color: #F3F4F4;
		font-size: 18px;
		text-align: left;
		background-color: #F25C2A;
		width: 868px;
		padding: 16px 24px 16px 24px;
		border-radius: 5px;
		gap: 40px;
		border: 1px;
		
		.learn-more-bolt {
			margin-right: 50px;
			margin-top: 5px;
			float: left;
		}
	}



	.underline {
		text-decoration: underline;
	}
}
