
@import "src/client_customizations/scss/client_master.scss";

#AustinBikeMap {
    
.header {
    margin: 40px;
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 0px;
    text-align: center;
    }
.subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
    margin-bottom: 40px;
    }
.austinMap {
    display: flex;
    justify-content: center;    
    background-color: #f3f4f4;
    padding-bottom: 5rem;
    }
}