@import "src/client_customizations/scss/client_master.scss";

.ERidesCard {
    display: flex;
    text-decoration: none;
    color: inherit;
    flex-direction: column;
    border-radius: 2px;
    height: 340px;
    padding: 0px 0px 16px 0px;
    box-shadow: 0px 20px 41px 0px #0000001A;

    &:hover {
       text-decoration: none !important;
       color: inherit !important;
    }

    .ERidesCardTop {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        padding: 15px;
        .up-to {
            font-size: 14px;
            font-weight: 400;
        }
        .price {
            font-size: 32px;
            font-weight: 400;
        }
    }
    .ERidesCardBottom {
        margin: 25px 20px 0px 20px;
        text-align: center;
        align-items: center;

    }

}