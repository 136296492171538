@import "src/client_customizations/scss/client_master.scss";

.DealerLocationCard {
  background-color: $DealerLocationCard-background-color;
  padding: 10px;
  border-radius: $DealerLocationCard-border-radius;
  border: $DealerLocationCard-border;
  margin: 10px 5px;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
    p {
      width: 100%;
      margin-bottom: 0;
      padding-top: 4px;
    }

    .badge-oem-type {
      display: block;
    }

    .badge-oem-type img {
      padding: 0;
    }
    .badge-fuel-type {
      right: 8px;
      top: 8px;
    }

    h2{
      max-width: 70%;
      display: inline-flex;
      vertical-align: top;
    }
    .contactRow {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      padding-top: 8px;
      span {
        padding: 8px;
      }
      padding-bottom: 8px;
    }
    // .contact:hover {
    //   background-color: yellow;
    // }
    .shop-button {
      background-color: #D8400E;
      width: fit-content;
      margin-top: 8px;
      margin-bottom: 8px;

    }
 }

@media (max-width:600px){
  .DealerCardTop {
    max-width: 66.66667%;
  }
}
@media (min-width: 576px) {
  .DealerCardTop {  }

  .DealerCardBottom > * {
    padding: 10px 15px;
  }
  .DealerCardTop .badge-oem-type {
    right: 15px;
    top: 15px;
  }
  .DealerCardTop .badge-locally-available {
    right: 35px;
    top: 15px;
  }
}