@import "src/client_customizations/scss/client_master.scss";

/* Apply yellow span underline for mobile views */
.Header {
	background-color: $Header-background-color;
	border-bottom: $Header-border-bottom;
	a {
		color:$Header-link-color;
	}
	.navbar {
		padding-top: 0;
		padding-bottom: 0;
		.navbar-brand {
			padding-top: 0;
			padding-bottom: 0;
			img {
				width: 160px;
			}
		}
		.navbar-collapse {
			a.active {
				span {
					color: $Header-navbar-link-color;
					text-decoration: none;
				}
			}
		}
	}
	.nav-item {
		margin: 5px;
		display: flex;
	}
	.dropdown-menu {
		.dropdown-item:hover{
			background-color: #F2F2F2;
			color: #F25C2A
		}
		.dropdown-item.active{
			background-color: #F2F2F2;
			color: #F25C2A
		}
	}
}
.skip-to-content-link {
	position: absolute;
	top: -1000px;
	left: -1000px;
	height: 1px;
	width: 1px;
	text-align: left;
	overflow: hidden;
}
a.skip-to-content-link {
	&:active {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
	&:focus {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
	&:hover {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
}
.navbar-toggler {
	border-color: $Header-navbar-toggler-border-color;
	color:$Header-navbar-toggler-color;
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(241,92,42, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	}
}
@media (min-width: 992px) {
	.Header {
		.navbar {
			padding: 0;
			.navbar-collapse {
				a {
					padding: 10px;
					border-radius: 4px;
					font-weight: 700;
					letter-spacing: 0.6px;
					text-decoration: none;
				}
				a.active {
					font-weight: bold;
					color: #C93C0D;
				}
			}
		}
	}
}
