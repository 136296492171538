@import "src/client_customizations/scss/client_master.scss";


#ERidesRebateCards {

.eRides-cards {
    display: flex;
    justify-content: center;
}
.eRides-title {
    margin: 2rem 0rem 1rem 0rem;
    font-size: 40px;
    display: flex;
     justify-content: center;
}
.eRides-subTitle {
    margin: 0rem 0rem 2rem 0rem;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
    width: 660px;
    
}
.eRides-button {
    margin: 1rem 0rem 3rem 0rem;
    display: flex;
    justify-content: center;
}
}