@import "src/client_customizations/scss/client_master.scss"; 

.inventory-card {
  padding-bottom: 16px;
  img {
    max-width:100%;
    max-height:100%;
  }
  .inventory-card-details {
    background-color: $Inventory-card-details-background;
    .detail {
      font-weight: bold;
    }
    .row {
      margin: 0;
    }
  }
  .vehicle-details {
    background-color: #D8400E;
    text-align: center;
    padding: 10px 8px;
    a {
      color: white;
    }
  }
  .model {
      font-weight: 700;
      font-size: 24px;
  }
  .make {
      font-size: 24px
  }
  .detail-title {
      padding-bottom: 8px
  }
  .inventory-card-bottom {
      img {
          padding-right: 4px;
      }
      a {
          text-decoration: none;
      }
      .dealer-name {
        color: #D8400E;
        font-weight: 700;
      }
  }
  .stock-image-disclaimer {
    p {
      font-size: .7rem;
    }
  }

} 
